//// Authentication & Authorization
export type AuthGroup = 'ROLE_ADMIN' | 'ROLE_APPROVER' | 'ROLE_COORDINATOR' | 'ROLE_REPRESENTATIVE'

//// Language
export const DefaultLanguage = 'en'

//// Header code
export const HeaderCode = {
    HTTP_OK: 200,
    HTTP_ERROR: 422,
    HTTP_NOT_FOUND: 404,
    HTTP_PERMISSION_ERROR: 403,
    HTTP_FORBIDDEN: 401,
    HTTP_TOKEN_EXPIRED: 423,
    HTTP_SERVER_ERROR: 500,
    HTTP_ACCOUNT_EXITS: 462,
    HTTP_ACCOUNT_NOT_ACTIVATED: 465,
    HTTP_SOCIAL_CONNECT_REGISTER: 466,
}

export const SystemId = 100

//// Duration
export const DurationConst = {
    ALERT_TIME: 3000,
    TOAST_TIME: {
        MESSAGE: 3000,
        ERROR: 10000,
    },
}

/// Firebase
export const FirebaseConst = {}

/// API path
export const ApiPath = {
    ADMIN: {
        ACCESS_ADMIN: {
            PATH: 'admin/access',
            EDIT: {
                PATH: 'admin/access/{accessGroupId}',
            },
        },
        USER_ADMIN: {
            PATH: 'admin/user',
            DETAIL: {
                PATH: 'admin/user/{userId}',
                DEVICE: {
                    PATH: 'user-admin/{userId}/devices',
                },
            },
        },
        VERIFY_CODE: {
            PATH: 'admin/verify-code',
            DETAIL_PATH: 'admin/verify-code/{id}',
        },
    },
    CONFIG: {
        PATH: 'config',
    },
    AUTH: {
        LOGIN: {
            PATH: 'access/login',
        },
        SOCIAL_LOGIN: {
            PATH: 'access/social-login',
        },
        GOOGLE_URL: {
            PATH: 'access/google/url',
        },
        REFRESH_TOKEN: {
            PATH: 'access/refresh-token',
        },
        REGISTER: {
            CHECK_PHONE_NUMBER: 'access/register/phone-number/check',
            VERIFY_PHONE_NUMBER: 'access/register/phone-number/verify',
        },
    },
    USER: {
        LOGOUT: 'user/logout',
        PROFILE: 'user/me',
        PROFILE_FORM_STRINGIFY: {
            PATH: 'user/me/form-stringify',
        },
        CHANGE_PASSWORD: 'user/change-password',
    },
    FEEDBACK: {
        PATH: 'cms/feedback',
        EDIT: {
            PATH: 'cms/feedback/{feedbackId}',
        },
    },
    WORKING: {
        PATH: 'working',
        ADD_EMPLOYEE: {
            PATH: 'working/add-employee',
        },
        REORDER_EMPLOYEES: {
            PATH: 'working/reorder-employees',
        },
        REMOVE_EMPLOYEE: {
            PATH: 'working/remove-employee',
        },
        ADD_TURN: {
            PATH: 'working/add-turn',
        },
        UPDATE_TURN: {
            PATH: 'working/update-turn',
        },
        UPDATE_MULTIPLE_TIPS: {
            PATH: 'working/update-multiple-tips',
        },
        DELETE_TURN: {
            PATH: 'working/delete-turn/{turnId}',
        },
        ACTUALLY_CASH: {
            PATH: 'working/actually-cash',
        },
        NOTE: {
            PATH: 'working/note',
        },
        OUTPUT: {
            PATH: 'working/output',
        },
    },
    EMPLOYEES: {
        PATH: 'employee',
        FORM_STRINGIFY: {
            PATH: 'employee/form-stringify',
        },
    },
    GIFT_CARD: {
        PATH: 'gift-card',
        CREATE_CODE: {
            PATH: 'gift-card/create-code',
        },
        SELL_NEW_CARD: {
            PATH: 'gift-card/sell',
        },
        CHECK_CODE: {
            PATH: 'gift-card/check',
        },
        CHECK_MULTIPLE_BALANCE: {
            PATH: 'gift-card/check-multiple-balance',
        },
    },
    REPORT: {
        PATH: 'report',
        FORMAT_1: {
            PATH: 'report/format1',
        },
    },
    SALON: {
        PATH: 'salon',
        PRICE_LIST: {
            PATH: 'salon/price-list',
        },
    },
    SALON_SERVICE: {
        PATH: 'salon/service',
        GROUP: {
            PATH: 'salon/service/group',
        },
    },
    CHECK_IN: {
        PATH: 'check-in',
        CHECK_CUSTOMER: {
            PATH: 'check-in/check-customer',
        },
    },
}

/// Routing
export const RouterPath = {
    AUTH: {
        PATH: 'auth',
        LOGIN: {
            PATH: 'login',
            FULL_PATH: 'auth/login',
        },
        GET_LINK: {
            PATH: 'get-link',
            FULL_PATH: 'auth/get-link',
        },
        GOOGLE_CALLBACK: {
            PATH: 'google/callback',
            FULL_PATH: 'auth/google/callback',
        },
        FACEBOOK_CALLBACK: {
            PATH: 'facebook/callback',
            FULL_PATH: 'auth/facebook/callback',
        },
        APPLE_CALLBACK: {
            PATH: 'apple/callback',
            FULL_PATH: 'auth/apple/callback',
        },
    },
    ADMIN: {
        TITLE: 'Administrator',
        PATH: 'admin',
        ACCESS: {
            TITLE: 'Access',
            PATH: 'access',
            FULL_PATH: 'admin/access',
        },
        USER: {
            TITLE: 'User',
            PATH: 'user',
            FULL_PATH: 'admin/user',
            DEVICE: {
                PATH: 'device',
                FULL_PATH: 'admin/user/device',
            },
        },
        FEEDBACK: {
            TITLE: 'Feedback',
            PATH: 'feedback',
            FULL_PATH: 'admin/feedback',
        },
        VERIFY_CODE: {
            TITLE: 'Verify code',
            PATH: 'verify-code',
            FULL_PATH: 'admin/verify-code',
        },
    },
    USER: {
        PATH: 'user',
    },
    PROFILE: {
        TITLE: 'Profile',
        PATH: 'profile',
        FULL_PATH: 'profile',
    },
    WORKING: {
        TITLE: 'Working',
        PATH: 'working',
        FULL_PATH: 'working',
    },
    EMPLOYEES: {
        TITLE: 'Employees',
        PATH: 'employees',
        FULL_PATH: 'employees',
    },
    GIFT_CARD: {
        TITLE: 'Gift card',
        PATH: 'gift-card',
        FULL_PATH: 'gift-card',
        MANAGE: {
            TITLE: 'Manage',
            PATH: 'manage',
            FULL_PATH: 'gift-card/manage',
        },
        PRINT_LABEL: {
            TITLE: 'Print label',
            PATH: 'print-label',
            FULL_PATH: 'gift-card/print-label',
        },
    },
    REPORT: {
        TITLE: 'Report',
        PATH: 'report',
        FULL_PATH: 'report',
    },
    SALON_SERVICE: {
        TITLE: 'Services',
        PATH: 'service',
        FULL_PATH: 'service',
    },
    CHECK_IN: {
        TITLE: 'Check in',
        PATH: 'check-in',
        FULL_PATH: 'check-in',
        SCREEN: {
            TITLE: 'Check in screen',
            PATH: 'screen',
            FULL_PATH: 'check-in/screen',
        },
    },
    SETTING: {
        TITLE: 'Settings',
        PATH: 'setting',
        FULL_PATH: 'setting',
        GENERAL_CONFIG: {
            TITLE: 'General config',
            PATH: 'general',
            FULL_PATH: 'setting/general',
        },
        PRICE_LIST: {
            TITLE: 'Price list',
            PATH: 'price-list',
            FULL_PATH: 'setting/price-list',
        },
    },
    ERRORS: {
        _500: '500',
        _404: '404',
        PATH: 'errors',
        NO_SALON: {
            PATH: 'no-salon',
            FULL_PATH: 'errors/no-salon',
        },
    },
}

/// Date
export const DateFormat = {
    DEFAULT_FORMAT: 'DD/MM/YYYY hh:mm:ss',
}

/// SVG
export const BaseSVG: any = {
    baseSVG:
        '<path d="M30.55 12.5l-11.050-11.050c-0.95-0.95-2.15-1.45-3.5-1.45s-2.55 0.5-3.5 1.45l-11.050 11.050c-0.95 0.95-1.45 2.15-1.45 3.5s0.5 2.55 1.45 3.5l11.050 11.050c0.95 0.95 2.2 1.45 3.5 1.45 1.35 0 2.55-0.5 3.5-1.45l11.050-11.050c0.95-0.95 1.45-2.2 1.45-3.5s-0.5-2.55-1.45-3.5z"/>',
    baseOutlineSVG:
        '<path d="M28.75 12.95l-9.7-9.7c-0.8-0.8-1.9-1.25-3.050-1.25s-2.25 0.45-3.050 1.25l-9.7 9.7c-0.8 0.8-1.25 1.9-1.25 3.050s0.45 2.25 1.25 3.050l9.65 9.65c0.8 0.8 1.9 1.25 3.050 1.25s2.25-0.45 3.050-1.25l9.65-9.65c0.8-0.8 1.25-1.9 1.25-3.050s-0.35-2.25-1.15-3.050zM27.4 17.75l-9.65 9.65c-0.45 0.45-1.1 0.75-1.75 0.75s-1.3-0.25-1.75-0.75l-9.65-9.65c-0.45-0.45-0.75-1.1-0.75-1.75s0.25-1.3 0.75-1.75l9.65-9.65c0.45-0.45 1.1-0.75 1.75-0.75s1.3 0.25 1.75 0.75l9.65 9.65c0.45 0.45 0.75 1.1 0.75 1.75s-0.25 1.3-0.75 1.75z"></path>',
}

export const DEFAULT_LIMIT_PAGINATION: number = 20
