<div *ngIf="sharedService.load" class="slider">
  <div class="line"></div>
  <div class="subline inc"></div>
  <div class="subline dec"></div>
</div>
<div class="header-container">
  <div class="logo-container">
    <a
      href="#"
      class="sidebar-toggle"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
      (click)="toggleSidebar()"
    >
      <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
    </a>
    <img id="main-logo" src="./assets/images/logo_text.png" width="220" />
    <div id="header-school-sm">
      <div class="salon-list" *ngIf="(sharedService.salons?.length || 0) > 1">
        <nb-user
          [matMenuTriggerFor]="accountMenu"
          size="large"
          name="{{ sharedService.currentSalon?.name }}"
          [picture]="sharedService.currentSalon?.avatarUrl | ImageUrlPipe"
          showTitle="false"
        >
        </nb-user>
        <mat-menu #accountMenu="matMenu" class="school-menu">
          <nb-card>
            <nb-card-body>
              <nb-radio-group [value]="sharedService.currentSalon?.id">
                <nb-radio
                  *ngFor="let salon of sharedService.salons"
                  [value]="salon.id"
                  (valueChange)="onSalonChanged($event)"
                >
                  <nb-user
                    size="large"
                    name="{{ salon.name }}"
                    showTitle="false"
                    [picture]="salon?.avatarUrl | ImageUrlPipe"
                  >
                  </nb-user>
                </nb-radio>
              </nb-radio-group>
            </nb-card-body>
          </nb-card>
        </mat-menu>
      </div>
    </div>
    <!--    <div id="header-school-xs">-->
    <!--      <div class="school-info" *ngIf="sharedService.salons?.length">-->
    <!--        <nb-user-->
    <!--          [matMenuTriggerFor]="accountMenu"-->
    <!--          size="large"-->
    <!--          name=""-->
    <!--          [picture]="sharedService.currentSalon?.avatarUrl | ImageUrlPipe"-->
    <!--          showTitle="false"-->
    <!--        >-->
    <!--        </nb-user>-->
    <!--        <mat-menu #accountMenu="matMenu" class="school-menu">-->
    <!--          <nb-card>-->
    <!--            <nb-card-body>-->
    <!--              <nb-radio-group [value]="sharedService.currentSalon?.id">-->
    <!--                <nb-radio-->
    <!--                  *ngFor="let salons of sharedService.salons"-->
    <!--                  [value]="salons.id"-->
    <!--                  (valueChange)="onSchoolChanged($event)"-->
    <!--                >-->
    <!--                  <nb-user-->
    <!--                    size="large"-->
    <!--                    name="{{ salons.name }}"-->
    <!--                    showTitle="false"-->
    <!--                    [picture]="salons?.avatarUrl | ImageUrlPipe"-->
    <!--                  >-->
    <!--                  </nb-user>-->
    <!--                </nb-radio>-->
    <!--              </nb-radio-group>-->
    <!--            </nb-card-body>-->
    <!--          </nb-card>-->
    <!--        </mat-menu>-->
    <!--      </div>-->
    <!--      <div class="school-name-wrapper">-->
    <!--        <div class="school-name">{{ sharedService.currentSalon?.name }}</div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action
      class="user-action"
      *nbIsGranted="['view', 'user']"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
    >
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="sharedService.currentUser?.firstName"
        [picture]="sharedService.currentUser?.avatarUrl | ImageUrlPipe"
        size="large"
        nbContextMenuTag="user-profile-context-menu"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
