import { Component, OnInit, HostBinding } from '@angular/core'
import { OverlayContainer } from '@angular/cdk/overlay'
import { SharedService } from './@core/services/shared.service'

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
    constructor(public overlayContainer: OverlayContainer, private sharedService: SharedService) {}

    @HostBinding('class') componentCssClass

    ngOnInit() {
        // this.appService.theme.subscribe(data => {
        //     this.onSetTheme(data);
        // });
    }

    private onSetTheme(theme) {
        const overlayContainerClasses = this.overlayContainer.getContainerElement().classList
        const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) =>
            item.includes('-theme')
        )
        if (themeClassesToRemove.length) {
            themeClassesToRemove.forEach((element) => {
                if (element !== theme) {
                    overlayContainerClasses.remove(element)
                }
            })
        }
        this.overlayContainer.getContainerElement().classList.add(theme)
        this.componentCssClass = theme
    }
}
