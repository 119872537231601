import { NgModule, ModuleWithProviders, Injectable } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
    NbAuthModule,
    NbPasswordAuthStrategy,
    NbAuthOAuth2JWTToken,
    NbAuthJWTInterceptor,
    NbAuthStrategyClass,
    NbPasswordAuthStrategyOptions,
} from '@nebular/auth'
import { environment } from '../../environments/environment'
import { Error404Component } from './errors/error404/error404.component'
import { Error500Component } from './errors/error500/error500.component'
/// services
import { LayoutService, ImageService, LocalStorageService } from './utils'
import { NbSecurityModule, NbRoleProvider } from '@nebular/security'

import { of as observableOf } from 'rxjs'
import { RouterPath, ApiPath } from '../config/global-const'
///
import { AuthGuard } from './guards/auth-guard.service'
import { HTTP_INTERCEPTORS } from '@angular/common/http'

export class NbSimpleRoleProvider extends NbRoleProvider {
    getRole() {
        // here you could provide any role based on any auth flow
        return observableOf('guest')
    }
}

@Injectable()
export class PvsAuthStrategy extends NbPasswordAuthStrategy {
    // multiple AuthStrategy
    // https://github.com/akveo/nebular/issues/682
    static setup(options: NbPasswordAuthStrategyOptions): [NbAuthStrategyClass, NbPasswordAuthStrategyOptions] {
        return [PvsAuthStrategy, options]
    }
}

export const NB_CORE_PROVIDERS = [
    ...NbAuthModule.forRoot({
        strategies: [
            NbPasswordAuthStrategy.setup({
                name: 'email',
                baseEndpoint: environment.apiUrl,
                token: {
                    class: NbAuthOAuth2JWTToken,
                    key: 'data.token',
                },
                // refreshToken: {
                //     endpoint: 'refresh-token',
                //     method: 'post',
                //     requireValidToken: true,
                //     redirect: {
                //         success: null,
                //         failure: null,
                //     },
                //     defaultErrors: ['Something went wrong, please try again.'],
                //     defaultMessages: ['Your token has been successfully refreshed.'],
                // },
                login: {
                    endpoint: ApiPath.AUTH.LOGIN.PATH,
                    method: 'post',
                    redirect: {
                        success: RouterPath.WORKING.FULL_PATH,
                        failure: null,
                    },
                    defaultMessages: ['Login successful'],
                    defaultErrors: ['Something went wrong! Please try again later.'],
                },
                logout: {
                    endpoint: ApiPath.USER.LOGOUT,
                    method: 'post',
                    redirect: {
                        success: RouterPath.AUTH.LOGIN.FULL_PATH,
                        failure: null,
                    },
                },
            }),
            PvsAuthStrategy.setup({
                name: 'oauth',
                baseEndpoint: environment.apiUrl,
                token: {
                    class: NbAuthOAuth2JWTToken,
                    key: 'data.token',
                },
                login: {
                    endpoint: ApiPath.AUTH.SOCIAL_LOGIN.PATH,
                    method: 'post',
                    redirect: {
                        success: RouterPath.WORKING.FULL_PATH,
                        failure: null,
                    },
                    defaultMessages: ['Login successful.'],
                    defaultErrors: ['Incorrect email / phone number or password, please try again'],
                },
                logout: {
                    endpoint: ApiPath.USER.LOGOUT,
                    method: 'post',
                    redirect: {
                        success: RouterPath.AUTH.LOGIN.FULL_PATH,
                        failure: null,
                    },
                },
            }),
        ],
        forms: {
            validation: {
                password: {
                    minLength: 8,
                    maxLength: 100,
                },
            },
        },
    }).providers,
    NbSecurityModule.forRoot({
        accessControl: {
            guest: {
                view: '*',
            },
            user: {
                parent: 'guest',
                create: '*',
                edit: '*',
                remove: '*',
            },
        },
    }).providers,
    {
        provide: NbRoleProvider,
        useClass: NbSimpleRoleProvider,
    },
    LayoutService,
    ImageService,
    LocalStorageService,
    PvsAuthStrategy,
]

@NgModule({
    declarations: [Error404Component, Error500Component],
    imports: [CommonModule],
    exports: [Error404Component, Error500Component, NbAuthModule],
    providers: [AuthGuard],
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                ...NB_CORE_PROVIDERS,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: NbAuthJWTInterceptor,
                    multi: true,
                },
            ],
        }
    }
}
