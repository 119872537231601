import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { CoreModule } from './@core/core.module'
import { SharedService } from './@core/services/shared.service'
import { PvsRequestFactoryService } from './@core/services/pvs-request-factory.service'
import { ThemeModule } from './@theme/theme.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NbMenuModule, NbSidebarService, NbToastrModule, NbDatepickerModule, NbDialogModule } from '@nebular/theme'
import { NbDateFnsDateModule } from '@nebular/date-fns'
import { QrCodeModule } from 'ng-qrcode'
import { MatPaginatorIntl } from '@angular/material/paginator'
///// Firebase - start
import { AngularFireModule } from '@angular/fire'
/// storage
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage'
import { DefaultInterceptor } from './@core/interceptors/default-interceptor'
import { ImageCropperModule } from 'ngx-image-cropper'

///// Firebase - end

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule.forRoot(),
        ThemeModule.forRoot(),
        NbToastrModule.forRoot(),
        NbMenuModule.forRoot(),
        NbDatepickerModule.forRoot(),
        NbDateFnsDateModule.forRoot({
            format: 'yyyy-MM-dd',
        }),
        NbDialogModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        FormsModule,
        AngularFireStorageModule,
        ImageCropperModule,
        // AngularFireModule.initializeApp(environment.firebaseConfig),
        QrCodeModule,
    ],
    providers: [
        SharedService,
        PvsRequestFactoryService,
        NbSidebarService,
        // { provide: BUCKET, useValue: environment.firebaseConfig.storageBucket },
        { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
