import { Injectable, Injector } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Method, PvsRequestService, PvsRequestParams } from './pvs-request.service'
import _ from 'lodash'

@Injectable()
export class PvsRequestFactoryService {
    constructor(private http: HttpClient, private injector: Injector) {}

    public createUrl(apiPath: string, pathParams: PvsRequestParams = null) {
        let url = environment.apiUrl + apiPath
        if (pathParams) {
            for (const [name, value] of Object.entries(pathParams)) {
                if (typeof value === 'undefined') {
                    const errorMessage = 'The value of url param is required!'
                    console.error(errorMessage)
                    throw new Error(errorMessage)
                }
                const stringValue = value === null ? 'null' : value.toString()
                url = url.replace(`{${name}}`, stringValue)
            }
            return url
        }
        return url
    }

    public buildFormStringify(data: any, file?: File, fileFieldName?: string): FormData {
        const clone = _.cloneDeep(data)
        const formData = new FormData()
        if (file && file instanceof File) {
            if (!fileFieldName) {
                throw new Error('Field name of the file is required!')
            }
            formData.append(fileFieldName, file)
            delete clone[fileFieldName]
        }
        formData.append('stringify', JSON.stringify(clone))
        return formData
    }

    public requestGet(url: string, queryParams: PvsRequestParams = null, responseAsFile = false): PvsRequestService {
        const ins = new PvsRequestService(this.http, this.injector)
        ins.method = Method.GET
        ins.url = url
        ins.queryParams = queryParams
        ins.responseAsFile = responseAsFile
        return ins
    }

    public requestPost(url: string, body = {}, isFormData = false): PvsRequestService {
        const ins = new PvsRequestService(this.http, this.injector)
        ins.method = Method.POST
        ins.url = url
        ins.body = body
        ins.isFormData = isFormData
        return ins
    }

    public requestPut(url: string, body = {}, isFormData = false): PvsRequestService {
        const ins = new PvsRequestService(this.http, this.injector)
        ins.method = Method.PUT
        ins.url = url
        ins.body = body
        ins.isFormData = isFormData
        return ins
    }

    public requestDelete(url: string, queryParams: PvsRequestParams = null): PvsRequestService {
        const ins = new PvsRequestService(this.http, this.injector)
        ins.method = Method.DELETE
        ins.url = url
        ins.queryParams = queryParams
        return ins
    }
}
