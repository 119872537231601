import { Injectable, Injector } from '@angular/core'
import { SharedService } from './shared.service'
import { ApiPath, HeaderCode, SystemId } from '../../config/global-const'
import { environment } from '../../../environments/environment'
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http'
import _ from 'lodash'

export interface PvsResponsePagination {
    current_page: number
    last_page: number
    per_page: number
    total_pages: number
    total_rows: number
}

export type PvsResponseCallback = (r: PvsResponse) => void

export interface PvsResponse {
    name?: string
    code?: number
    message?: string
    errorMessage?: string
    data?: any
    pagination?: PvsResponsePagination
}

export interface PvsRequestParams {
    [name: string]: string | number
}

export enum Method {
    GET,
    POST,
    PUT,
    DELETE,
}

@Injectable()
export class PvsRequestService {
    private _sharedService: SharedService
    private _httpClient: HttpClient
    public method: Method
    public url: string
    public queryParams: PvsRequestParams
    public responseAsFile: boolean
    public isFormData: boolean
    public body: any
    public usingLoadingBar: boolean = true
    private _retryCount: number = 0

    constructor(private httpClient: HttpClient, private injector: Injector) {
        this._httpClient = httpClient
    }

    private get sharedService(): SharedService {
        if (!this._sharedService) {
            this._sharedService = this.injector.get(SharedService)
        }
        return this._sharedService
    }

    private createOptions(queryParams: PvsRequestParams = null, isFormData = false) {
        const currentLanguage = this.sharedService.localStorageService.currentLanguageCode
        const token = this.sharedService.localStorageService.accessToken
        // console.log('access token', token.slice(-4))
        const headers = {
            'x-access-token': token,
            'Accept-Language': currentLanguage,
            'Accept-Platform': 'web',
            'timezone-offset': new Date().getTimezoneOffset().toString(),
        }
        if (!isFormData) {
            headers['Content-Type'] = 'application/json'
        }
        const options: any = {
            headers: new HttpHeaders(headers),
        }
        const sendParams = {}
        const tmpQ = { ...queryParams }
        if (this.sharedService?.currentSalon?.id) {
            tmpQ.sid = this.sharedService?.currentSalon?.id
        }
        for (const [name, value] of Object.entries(tmpQ)) {
            if (value !== null) {
                sendParams[name] = value
            }
        }
        if (Object.keys(sendParams).length > 0) {
            options.params = Object.getOwnPropertyNames(sendParams).reduce(
                (p, key) => p.set(key, sendParams[key]),
                new HttpParams()
            )
        }
        return options
    }

    private createHttpRequest() {
        switch (this.method) {
            case Method.GET: {
                const options: any = this.createOptions(this.queryParams)
                if (this.responseAsFile) {
                    options.responseType = 'blob'
                }
                return this._httpClient.get(this.url, options)
            }
            case Method.POST:
            case Method.PUT: {
                const options = this.createOptions(null, this.isFormData)
                let body = this.body
                if (this.body && typeof this.body !== 'string' && !this.isFormData) {
                    body = JSON.stringify(body)
                }
                if (this.method === Method.POST) {
                    return this._httpClient.post(this.url, body, options)
                } else {
                    return this._httpClient.put(this.url, body, options)
                }
            }
            case Method.DELETE: {
                const options: any = this.createOptions(this.queryParams)
                return this._httpClient.delete(this.url, options)
            }
        }
    }

    private refreshToken(successCallback) {
        const options = this.createOptions()
        const body = JSON.stringify({
            accessToken: this.sharedService.localStorageService.accessToken,
            refreshToken: this.sharedService.localStorageService.refreshToken,
        })
        const url = environment.apiUrl + ApiPath.AUTH.REFRESH_TOKEN.PATH
        this._httpClient.post(url, body, options).subscribe(
            (res: any) => {
                this.sharedService.localStorageService.accessToken = res.data.accessToken
                console.log('new access token', res.data.accessToken.slice(-4))
                return successCallback()
            },
            (err) => {
                this.sharedService.showAndHandleError(err)
            }
        )
    }

    private async refreshTokenPromise() {
        const options = this.createOptions()
        const body = JSON.stringify({
            accessToken: this.sharedService.localStorageService.accessToken,
            refreshToken: this.sharedService.localStorageService.refreshToken,
        })
        const url = environment.apiUrl + ApiPath.AUTH.REFRESH_TOKEN.PATH
        try {
            const res: any = await this._httpClient.post(url, body, options).toPromise()
            this.sharedService.localStorageService.accessToken = res.data.accessToken
            console.log('new access token', res.data.accessToken.slice(-4))
            return true
        } catch (err) {
            this.sharedService.showAndHandleError(err)
            return false
        }
    }

    public subscribe(nextCallback?: PvsResponseCallback | Function, errorCallback?, completedCallback?) {
        if (this.usingLoadingBar) {
            this.sharedService.setLoadingBar(true)
        }
        const httpRequest = this.createHttpRequest()
        return httpRequest.subscribe(
            (res: any) => {
                if (this.usingLoadingBar) {
                    this.sharedService.setLoadingBar(false)
                }
                if (typeof nextCallback === 'function') {
                    return nextCallback(res)
                }
                return null
            },
            (err) => {
                if (this.usingLoadingBar) {
                    this.sharedService.setLoadingBar(false)
                }
                const errorCode = err?.error?.code
                if (errorCode === HeaderCode.HTTP_TOKEN_EXPIRED && this._retryCount === 0) {
                    this._retryCount += 1
                    return this.refreshToken(() => {
                        this.subscribe(nextCallback, errorCallback, completedCallback)
                    })
                }
                if (typeof errorCallback === 'function') {
                    return errorCallback(err)
                }
                this.sharedService.showAndHandleError(err)
                return null
            },
            () => {
                if (typeof completedCallback === 'function') {
                    return completedCallback()
                }
                return null
            }
        )
    }

    public async toPromise(errorCallback?: Function, completedCallback?: Function): Promise<PvsResponse> {
        if (this.usingLoadingBar) {
            this.sharedService.setLoadingBar(true)
        }
        const httpRequest = this.createHttpRequest()
        try {
            const res = <PvsResponse>await httpRequest.toPromise()
            if (this.usingLoadingBar) {
                this.sharedService.setLoadingBar(false)
            }
            if (typeof completedCallback === 'function') {
                completedCallback()
            }
            return res
        } catch (err) {
            const errorCode = err?.error?.code
            if (errorCode === HeaderCode.HTTP_TOKEN_EXPIRED && this._retryCount === 0) {
                this._retryCount += 1
                const success = await this.refreshTokenPromise()
                if (this.usingLoadingBar) {
                    this.sharedService.setLoadingBar(false)
                }
                if (success) {
                    return this.toPromise(errorCallback)
                }
                return null
            }
            if (this.usingLoadingBar) {
                this.sharedService.setLoadingBar(false)
            }
            if (errorCallback) {
                return errorCallback(err)
            }
            if (err instanceof HttpErrorResponse && err.status === 500) {
                this.sharedService.showAndHandleError('Something went wrong')
            } else {
                this.sharedService.showAndHandleError(err)
            }
            return null
        }
    }
}
